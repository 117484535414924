@import "src/theme-styles.scss";

form {

  section .form-section-header {
    font-size: 14px;
    font-weight: 500;
    color: $form-section-title;
    margin-top: 24px;
    margin-bottom: 18px;
  }

  .form-group.form-group-spacing {
    margin-right: 12px;
  }

  .form-divider {
    border-top: 1px solid $color-primary;
    margin-top: 24px;
    margin-bottom: 18px;
  }
}
