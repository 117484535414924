@import "src/theme-styles.scss";
textarea {
  &[disabled] {
    filter: grayscale(1);
    background-color: $ng-select-disable !important;
    .ng-input > input {
      cursor: not-allowed !important;
    }
    ~ label {
      filter: grayscale(1);
      background-color: $ng-select-disable !important;
    }
  }
}
