@import "src/theme-styles.scss";
mat-radio-button {
  font-size: 13px;
  padding: 0px;
  line-height: 16px;
  font-weight: 500;
  color: #666a70;
  line-height: 35px;
  font-family: $font-family;
  .mat-radio-outer-circle,
  .mat-radio-container {
    width: 15px;
    height: 15px;
    border-width: 1.5px;
    margin-right: 2px;
  }
  .mat-ripple {
    display: none;
  }
  &.mat-radio-checked {
    .mat-radio-inner-circle {
      transform: scale(0.4);
      width: 15px;
      height: 15px;
    }
  }
}
