@import "src/theme-styles.scss";

/* Global Tab styles */
mat-tab-group {
  .mat-mdc-tab,
  .mat-mdc-tab:not(.mdc-tab--stacked){
    height: 24px;
    padding: 0 12px;

    &.mdc-tab--active .mdc-tab-indicator__content--underline,
    &.mdc-tab--active:focus .mdc-tab-indicator__content--underline {
      border-color: $color-secondary;
      border-width: medium;
    }

    &.mdc-tab--active span.mdc-tab__text-label,
    &.mdc-tab--active:focus span.mdc-tab__text-label {
      font-weight: 600;
    }

    .mdc-tab__text-label {
      font-weight: 500;
      font-family: $font-family;
      letter-spacing: -0.11px;
      text-transform: uppercase;
    }
    .mdc-tab__text-label:hover {
      color: $lite-blue-color;
    }
  }
}
