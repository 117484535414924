@import "src/theme-styles.scss";
input.form-control {
  &[disabled] {
    filter: grayscale(1);
    background-color: $ng-select-disable !important;
    .ng-input > input {
      cursor: not-allowed !important;
    }
    ~ label {
      filter: grayscale(1);
      background-color: $ng-select-disable !important;
    }
  }
}

.mdc-text-field--filled {
  .mdc-floating-label--float-above {
    mat-label {
      top: -10px;
      position: relative;
      z-index: 1000;
    }
  }
}

.mandatory-star {
  input ~ label {
    &::after {
      content: "*";
      color: $dark-red;
      margin-left: 1px;
      font-weight: 700;
      vertical-align: top;
    }
  }
}
