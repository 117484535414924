// @import "primeng/resources/themes/mdc-light-indigo/theme.css";
// declare primary color variable
$primary-color: #1777a7;
$text-color: #4a4a4a;

plato-data-grid {
  .column-header {
    .column-header-icons {
      margin-top: 8px;
    }
  }

  tr {
    outline: none;
  }
  input.p-inputtext {
    width: 75% !important;
  }
  .pi-minus:before {
    content: "";
  }
  .pi.pi-minus:before {
    content: "\e90f";
  }
  .pi.pi-minus.pi-check:before {
    content: "\e909";
  }
  .pi-chevron-right,
  .pi-chevron-down {
    color: $primary-color;
  }
  th.column-checkbox {
    padding: 0 5px 0 10px !important;
    vertical-align: middle !important;
    width: 30px;
  }
  td.column-checkbox {
    padding: 0 5px 0 10px !important;
    vertical-align: middle;
  }
  .p-checkbox {
    border: 1px solid $text-color;
    margin: 0;
    .p-checkbox-box {
      width: 14px;
      height: 14px;
      line-height: 14px;
    }
    .p-checkbox-icon {
      font-size: 14px;
      color: white;
      background: $primary-color;
    }
  }
  .p-datatable-thead > tr > th,
  .p-datatable-tbody > tr > td,
  .p-datatable-tfoot > tr > td {
    padding: 0.5em 0.5em;
  }
  .p-treetable-thead > tr > th,
  .p-treetable-tbody > tr > td,
  .p-treetable-tfoot > tr > td {
    padding: 0.5em 0.5em;
  }
  .p-treetable-caption.p-widget-header {
    visibility: hidden;
    height: 0;
  }
  .p-datatable-scrollable-header-box,
  .p-datatable-scrollable-footer-box {
    padding-right: 0 !important;
  }
  .grid-table {
    th {
      font-weight: 600;
    }
    table {
      text-align: left;
      color: $text-color;
      border: 1px solid #c8dde9;
      font-size: 13px;
      thead tr {
        background: #daf2f8;
        color: #023f65;
        text-transform: uppercase;
        font-size: 14px;
      }
      tbody {
        tr:nth-child(odd) {
          background: #f9f9f9;
        }
        tr:nth-child(even) {
          background: #ffffff;
        }
        tr.tr-highlight {
          background: #eff7cb !important;
        }
      }
    }
  }

  .grid-tree-table {
    table {
      color: #acb0af;
      border: 1px solid #d2edf4;
      thead.p-treetable-thead tr {
        background: #e1f8fc;
        color: #216d95;
        text-transform: uppercase;
        font-size: 13px;
      }
      tbody {
        font-size: 13px;
        tr:nth-child(odd) {
          background: #ffffff;
        }
        tr:nth-child(even) {
          background: #f9f9f9;
        }
      }
    }
    .expanded-row {
      background: #f0fafc;
    }
    .row-update {
      background: #eff7cb !important;
    }
    .p-treetable {
      .p-treetable-toggler {
        height: auto;
        margin-top: 0;
        top: 0;
        left: 2px;
      }
      .p-treetable-checkbox {
        margin: 0 10px 0 0;
      }
    }
    .pi-chevron-right {
      &::before {
        content: "";
      }
      position: relative;
      top: 5px;
      width: 0;
      height: 0;
      margin-right: 10px;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid #96c5e2;
    }
    .pi-chevron-down {
      &::before {
        content: "";
      }
      position: relative;
      top: 0px;
      left: -2px;
      width: 0;
      height: 0;
      margin-right: 10px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #106690;
    }
  }
}

.hidden {
  position: relative;
  z-index: -1000;
}

.p-datatable-tfoot {
  color: #4a4a4a;
  line-height: 40px;
}

.p-column-filter-menu-button {
  padding: 0 !important;
}

.pi-filter-icon,
.p-sortable-column-icon {
  font-size: 10px;
}

.p-column-filter-overlay {
  position: absolute;
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 3px;
  box-shadow:
    0 2px 4px -1px rgb(0 0 0 / 20%),
    0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
  min-width: 12.5rem;
  max-width: 25rem;
  h3 {
    margin: 0 0 8px 0;
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  .filter-label {
    padding: 12px 12px 0;
    text-transform: uppercase;
  }
  .filter-btn-wrapper {
    text-align: center;
  }
  .p-button {
    margin: 12px 15px 0 15px;
    &:last-child {
      margin-left: 20px;
    }
  }
  .p-column-filter-constraint {
    padding: 8px 12px 12px;
    border-bottom: 1px solid #dee2e6;
    &:last-child {
      border-bottom: 0;
    }
  }
  .p-multiselect {
    background: #ffffff;
    border: 1px solid #ced4da;
    transition:
      background-color 0.2s,
      color 0.2s,
      border-color 0.2s,
      box-shadow 0.2s;
    border-radius: 3px;
    line-height: 18px;
    .p-multiselect-label-container {
      overflow: hidden;
      flex: 1 1 auto;
      cursor: pointer;
    }
    .p-multiselect-label {
      display: block;
      white-space: nowrap;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 5px;
      transition:
        background-color 0.2s,
        color 0.2s,
        border-color 0.2s,
        box-shadow 0.2s;
      &.p-placeholder {
        color: #6c757d;
      }
    }
    .p-multiselect-trigger {
      background: transparent;
      color: #6c757d;
      width: 2.357rem;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    .p-multiselect-panel {
      position: absolute;
      background: #ffffff;
      color: #495057;
      border: 0 none;
      border-radius: 3px;
      box-shadow:
        0 2px 4px -1px rgb(0 0 0 / 20%),
        0 4px 5px 0 rgb(0 0 0 / 14%),
        0 1px 10px 0 rgb(0 0 0 / 12%);
    }
    .p-multiselect-token {
      cursor: default;
      display: inline-flex;
      align-items: center;
      flex: 0 0 auto;
      .p-multiselect-token-icon {
        cursor: pointer;
        margin-left: 8px;
      }
    }
    .p-multiselect-token {
      padding: 4px 8px;
      margin-right: 8px;
      background: #e3f2fd;
      color: #495057;
      border-radius: 3px;
    }
    .p-multiselect-items-wrapper {
      overflow: auto;
      padding: 5px 3px;
      .p-multiselect-items {
        margin: 0;
        padding: 0;
        list-style-type: none;
        .p-multiselect-item {
          margin: 0;
          padding: 5px 8px;
          border: 0 none;
          color: #495057;
          background: transparent;
          transition: box-shadow 0.2s;
          border-radius: 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          font-weight: 400;
          white-space: nowrap;
          position: relative;
          overflow: hidden;
        }
        .p-checkbox {
          margin-right: 8px;
          display: inline-flex;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          vertical-align: bottom;
          position: relative;
          .p-checkbox-box {
            border: 2px solid #ced4da;
            background: #ffffff;
            width: 20px;
            height: 20px;
            color: #495057;
            border-radius: 3px;
            transition:
              background-color 0.2s,
              color 0.2s,
              border-color 0.2s,
              box-shadow 0.2s;
          }
        }
      }
    }
  }
}

.p-skeleton {
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 4px;

  &:after {
    content: "";
    animation-duration: 1.2s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-timeline: auto;
    animation-range-start: normal;
    animation-range-end: normal;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-100%);
    z-index: 1;
  }
}
.p-skeleton:after {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0)
  );
}
