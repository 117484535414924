@import "src/theme-styles.scss";

plato-data-grid {
  .grid-lib-wrapper {
    font-family: $font-family;
    .grid-table {
      table {
        font-size: 13px;
        color: $grid-font;
        font-weight: normal;
        border: 1px solid $grid-border;
        line-height: 16px;
        thead {
          background: $white-color;
          tr {
            border-bottom: 1px solid $grid-border;
            background-color: $white-color;
            th {
              color: $plato-black;
              font-size: 12px;
              font-weight: 600;
              vertical-align: top;
            }
          }
        }
        t-foot,
        .ui-table-tfoot {
          background: $white-color !important;
        }
        tbody {
          tr {
            border-bottom: 1px solid $grid-border;
            vertical-align: top;
            &:hover {
              background: $table-row-hover;
            }
            &:nth-child(even) {
              background-color: $white-color;
            }
            &:nth-child(odd) {
              background-color: $white-color;
            }
            pricing-cell {
              font-size: 13px;
            }
            td {
              position: relative;
              .unit-pricing-cell {
                .table-cell-edit {
                  color: $unit-price-color;
                }
              }
              .table-cell-edit {
                &::after {
                  content: "";
                  background: url("../../../../assets/images/pencil-edit.png") no-repeat;
                  position: absolute;
                  top: 5px;
                  width: 16px;
                  height: 16px;
                  cursor: pointer;
                  opacity: 0.3;
                  margin-left: 10px;
                  background-size: 10px;
                  display: none;
                }
                position: relative;
                padding-left: 0px;
                color: $unit-cost-color;
                // border: 1px dotted #e0dddd;
                &:hover {
                  &::after {
                    opacity: 1 !important;
                  }
                }
                .input-error {
                  border-color: $red !important;
                }
                input {
                  height: 27px;
                  max-width: 48px !important;
                }
                a {
                  font-weight: 600;
                }
              }
              .table-cell-input input {
                width: 100%;
              }
              .cell-req-awaiting {
                background: #d0e6ef;
                color: darkred;
                position: absolute;
                left: 0;
                top: 0;
                right: 1px;
                float: right;
                height: 100%;
                line-height: initial;
                padding: 5px 15px;
                text-align: center;
                border-bottom: 1px solid #ccc;
                display: flex;
                align-items: initial;
                justify-content: center;
                font-size: 12px;
              }

              .req-awaiting {
                display: block;
                width: 100px;
                height: 26px;
                color: darkred;
              }
              .Auto {
                //color: #09bb4b;
                color: $grid-font;
              }
              .Manual {
                //color: #fd45fd;
                color: $grid-font;
              }
              .MediaOwnerPortal {
                //color: #FF250C;
                color: $grid-font;
              }
            }
          }
          .pricing-cell-wrapper {
            span,
            .table-cell-edit {
              font-weight: 600;
            }
          }
          .input-cell-wrapper {
            span,
            .table-cell-edit {
              font-weight: 600;
            }
          }
        }
      }
    }
    .cpt-label {
      color: $grid-font !important;
      font-size: 12px;
    }
  }
  .grid-tree-table {
    .pi-chevron-right {
      border-left: 8px solid #106690 !important;
    }
    .pi-chevron-down {
      border-top: 8px solid #96c5e2 !important;
    }
  }
}
