@import "src/theme-styles.scss";
table {
  border-color: $table-border;
  thead {
    background: $table-head-color;
    th {
      font-size: 13px !important;
      text-transform: uppercase;
      border-bottom-width: 1px !important;
      font-weight: 500;
      padding: 5px !important;
    }
  }
  tbody {
    tr {
      &:nth-child(odd) {
        background: $table-row-even-color;
      }
      td {
        padding: 5px !important;
      }
    }
  }
  &.gray-head {
    thead {
      background: $table-head-secondary;
      color: $table-head-secondary-font;
    }
  }
  &.blue-head {
    thead {
      background: $table-head-primary;
      th {
        color: $table-head-primary-font;
      }
    }
    &.row-bg {
      background: #f0fcfe;
    }
  }
}

.datatable-row-odd {
  background: $table-row-odd-color;
  &:hover {
    background: $table-row-hover !important;
  }
  .datatable-row-left,
  .datatable-row-center,
  .datatable-row-right {
    background: inherit !important;
    &:hover {
      background: $table-row-hover !important;
    }
  }
}
