@import "src/theme-styles.scss";

.ng-select {
  overflow: hidden;
  padding: 3px 3px 3px 10px;
  min-height: 35px;
  position: initial !important;

  .ng-select-container {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    max-height: 200px;
  }

  input {
    min-height: 28px;
    line-height: 28px;
  }

  &.scrollable {
    min-height: 100px;
    height: auto !important;
    overflow-y: scroll;
  }

  &.expanded {
    height: auto !important;
    overflow-y: visible !important;
    &.ng-select-multiple {
      .ng-select-container {
        height: auto !important;

        .ng-value-container {
          height: auto !important;
          margin-right: 20px;
        }
      }
    }

    .ng-clear-wrapper {
      width: auto;
      padding-left: 10px;
      padding-right: 10px;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
      background-color: $ng-select-border;
    }
  }

  &.ng-select-multiple,
  &.ng-select-single {
    .ng-select-container {
      overflow: initial !important;
      position: relative !important;
      overflow: hidden !important;
      max-height: 200px;
      line-height: 30px;
      height: 30px;
      margin-right: 26px;

      .ng-value-container {
        overflow: hidden !important;
        max-height: 200px;
        line-height: 30px;
        height: 30px;
        overflow: initial !important;
        position: relative !important;

        .ng-value {
          font-size: 11px;
          padding-right: 15px;
          padding-left: 2px;
          line-height: 18px;
          height: 21px;
          max-width: 190px;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 5px;
          border: 1px solid $ng-select-border;
          position: relative;
          margin: 5px 5px 5px 0px;

          .ng-value-icon {
            cursor: pointer;
            color: $ng-select-close;
            float: right;
            font-size: 24px;
            position: absolute;
            right: 2px;
            top: 0px;
            font-weight: 200;
          }
        }
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      overflow: hidden !important;
      line-height: 32px;

      .ng-value-container {
        .ng-value {
          border: 0 !important;
        }
      }
    }

    &.ng-select-focused {
      height: 38px !important;
    }
  }

  .ng-clear-wrapper {
    display: inline-block;
    font-size: 25px !important;
    line-height: 1;
    z-index: 2;
    position: relative;
    right: 22px;
    opacity: 2;

    .ng-clear {
      position: relative;
      line-height: 28px !important;
      font-size: 24px !important;
      color: $color-secondary;
    }
  }

  &.ng-select-focused {

    .ng-select-container,
    .ng-value-container {
      height: auto !important;
    }
  }

  &.ng-select-focused {
    height: auto !important;
    color: #495057;
    background-color: $ng-select-focus !important;
    border-color: $ng-select-focus-border !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0);
  }

  &.ng-select-disabled {
    filter: grayscale(1);
    background-color: $ng-select-disable !important;

    .ng-input>input {
      cursor: not-allowed !important;
    }

    ~label {
      filter: grayscale(1);
      background-color: $ng-select-disable !important;
    }
  }

  &:before {
    background-image: url("../../../../assets/images/down-arrow.svg") !important;
    background-repeat: no-repeat !important;
    background-size: 11px !important;
    position: absolute;
    width: 12px;
    height: 12px;
    padding: 1px;
    right: 16px;
    top: 13px;
    z-index: -1;
    cursor: pointer;
  }
}

.mandatory-star {
  ng-select~label {
    &::after {
      content: "*";
      color: $dark-red;
      margin-left: 1px;
      font-weight: 700;
      vertical-align: top;
    }
  }
}

.pl-menu_submenu .ng-select {
  position: initial !important;
}

span.ng-value-label.ng-star-inserted {
  margin-right: 5px;
}