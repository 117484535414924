@import "src/theme-styles.scss";

.mandatory-star {
  .mat-mdc-form-field-type-mat-input {
    label:has(~ input) {
      mat-label::after {
        content: "*";
        color: $dark-red;
        margin-left: 1px;
        font-weight: 700;
        vertical-align: top;
      }
    }
  }
}
