@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";
@import "assets/fonts/style.css";
@import "../node_modules/@swimlane/ngx-datatable/index.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/material.css";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "app/home/search/navbar/chips-wrapper.scss";
@import "app/core/components/css/pages.scss";
@import "app/core/components/css/ng-select.scss";
@import "app/core/components/css/input.scss";
@import "app/core/components/css/textarea.scss";
@import "app/core/components/css/mat-dialog.scss";
@import "app/core/components/css/custom-table.scss";
@import "app/core/components/css/grid.scss";
@import "app/core/components/css/mat-radio-button.scss";
@import "app/core/components/css/pagination.scss";
@import "app/core/components/css/notification-pop.scss";
@import "app/core/components/css/bs-date-picker.scss";
@import "app/core/components/css/mat-expansion.scss";
@import "app/core/components/css/buttons.scss";
@import "app/core/components/css/grid-status.scss";
@import "app/core/components/css/button.scss";
@import "app/core/components/css/checkbox.scss";
@import "app/core/components/css/mat-select.scss";
@import "app/core/components/css/mat-input.scss";
@import "app/core/components/css/mat-tab.scss";
@import "app/core/components/css/forms.scss";
@import "@angular/cdk/overlay-prebuilt.css";
@import "src/theme-styles.scss";

/* You can add global styles to this file, and also import other style files */

html,
body {
  min-width: 100%;
  max-height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

body {
  /* display: table; */
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  background-color: #ffffff;
  overflow-y: auto;
  /* font-family: 'Roboto', sans-serif !important; */
  font-family: $font-family;
}

.auth0-lock-container > .auth0-lock > .auth0-lock-overlay {
  background: linear-gradient(rgba(23, 119, 167, 0.85), rgba(23, 119, 167, 0.85)), url("./assets/images/login-bg.jpg") !important;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.p-tb-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

button,
input,
optgroup,
select,
textarea {
  font-family: $font-family;
}

button {
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
}

::-webkit-scrollbar {
  width: 11px;
  height: 12px;
}
.datatable-body::-webkit-scrollbar {
  border-bottom: 1px solid #ababab45;
}
.data-custom-table::-webkit-scrollbar {
  border-bottom: 1px solid #ababab45;
}
.pl-scroll::-webkit-scrollbar {
  width: 8px !important;
}

.pl-scroll::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 8px;
}
.notification-content::-webkit-scrollbar-thumb {
  background-color: #eff8f9;
  border: 1px solid #93c0d6;
  min-height: 40px;
}
/* default classes */

.pos-relative {
  position: relative;
}
a,
button {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
a {
  font-family: $font-family;
}

.gray-btn {
  background-color: $button-gray !important;
}

.gray-btn:hover {
  background-color: $button-gray-hover !important;
}

:focus {
  outline: -webkit-focus-ring-color auto 0;
}

.form-group label,
.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-label {
  color: #7c7b80;
  text-shadow: none;
  font-family: $font-family;
}

input,
select,
textarea,
ng-select {
  border: 1px solid #ccc;
  // border-radius: 0 !important;
  // background: transparent !important;
  font-family: $font-family;
  font-size: 14px;
  color: #000;
  border-radius: 2px !important;
}

.custom-loader {
  background: url("./assets/images/loader.gif");
  background-repeat: no-repeat;
  background-size: 100px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
}

input.file-input input[type="button"],
::-webkit-file-upload-button {
  float: right;
  background: #fff;
  border: 0;
  cursor: pointer;
  color: #000;
  padding: 0;
  letter-spacing: 40px;
  width: 0;
  height: 30px;
  padding-left: 6px;
}
input.file-input {
  background-image: url("assets/images/file-upload.svg") !important;
  background-size: 20px !important;
  background-repeat: no-repeat !important;
  opacity: 0.7;
  font-size: 12px;
  background-position: 95% 50% !important;
  right: 13px !important;
  cursor: pointer;
  height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 35px !important;
}

.file-upload input {
  padding: 3px;
  width: 100%;
}

label.label-normal {
  position: relative !important;
  left: 2px !important;
  margin-bottom: 10px;
  // margin: auto;
}
.form-group label {
  position: absolute;
  top: 8px;
  left: 15px;
  font-size: 0.9em;
  pointer-events: none;
  transition: 0.2s ease all;
  padding-right: 7px;
  color: $black-secondary;
}

.form-row input:focus ~ label,
.form-row input:not(:placeholder-shown) ~ label {
  left: 10px;
}
.form-row input:focus ~ label {
  top: -8px;
}

.form-group mat-select.ng-touched ~ label {
  top: -14px;
  left: -2px;
}

.form-group mat-select ~ label {
  top: 5px;
  left: 2px;
}

.italic::placeholder {
  font-style: italic;
}

input:focus ~ label,
textarea:focus ~ label,
.ng-select-focused ~ label,
.ng-selected-values ~ label,
ng-select:focus ~ label,
select:focus ~ label,
input:not(:placeholder-shown) ~ label,
.ng-select-filtered ~ label,
textarea:not(:placeholder-shown) ~ label {
  position: absolute;
  top: -11px;
  left: 7px;
  font-size: 10px;
  background: $color-sidenav;
  transition: 0.2s ease all;
  padding: 0 4px;
  padding-right: 8px;
  padding-bottom: 0px;
  margin-bottom: 0;
  line-height: 1rem;
  white-space: nowrap;
}

input:not(:placeholder-shown) ~ label {
  top: -8px;
}

span.mandatory-field {
  color: $red;
  line-height: 16px;
  position: absolute;
  right: 3px;
  top: 0;
  font-size: 15px;
  font-weight: 700;
}

md-input-container.md-input-focused label {
  color: blue;
}

select::-ms-expand {
  display: none;
}

select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

[placeholder]:focus::-webkit-input-placeholder {
  transition: opacity 0.2s 0.2s ease;
  opacity: 0;
}
.border-1 {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
}

nav input,
nav select,
nav textarea,
nav ng-select {
  font-size: 12px !important;
}

.form-group {
  position: relative;
}

.opacity-05 {
  opacity: 0.5;
}

.hover-none {
  border-color: #fff !important;
  cursor: default !important;
}
.hover-none:hover img {
  opacity: 0.5 !important;
}
.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.mat-mdc-checkbox-disabled {
  opacity: 0.4;
  pointer-events: none;
}

.cursor-disabled {
  cursor: not-allowed;
  pointer-events: none;
  filter: invert(60%) sepia(12%) saturate(7%) hue-rotate(129deg) brightness(100%) contrast(97%);
}

button.cursor-disabled {
  filter: invert(10%) sepia(12%) saturate(7%) hue-rotate(129deg) brightness(100%) contrast(97%);
}

.gray-out {
  filter: invert(60%) sepia(12%) saturate(7%) hue-rotate(129deg) brightness(100%) contrast(97%);
  &:hover {
    filter: invert(60%) sepia(12%) saturate(7%) hue-rotate(129deg) brightness(100%) contrast(97%) !important;
  }
}

.ng-select {
  min-height: 38px;
  &:before {
    content: "";
    opacity: 0.6;
    z-index: 0 !important;
  }
}

.nav-select-arrow {
  background-image: url("assets/images/down-arrow.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 11px !important;
  position: absolute;
  width: 12px;
  height: 12px;
  padding: 1px;
  right: 32px;
  top: 13px;
  z-index: -1;
  cursor: pointer;
}

.form-element-parent {
  .ng-select:before {
    right: 12px;
  }
}

.form-control:focus {
  height: 38px;
  color: #495057;
  background-color: rgba(176, 234, 245, 0.1) !important;
  border-color: #d0d0d0 !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0);
}

.form-control {
  box-sizing: border-box;
  font-size: 13px;
  color: $black-secondary;
  height: 38px;
}

.form-control.invalid {
  border: 1px solid red;
}

.form-control {
  &[read-only] ~ label {
    background-color: #e9ecef !important;
  }
}

.list-none {
  list-style: none;
}

a:hover,
a:focus {
  text-decoration: none !important;
}

button:focus {
  outline: 0 dotted !important;
  outline: 0 auto -webkit-focus-ring-color !important;
}

.text-upper {
  text-transform: uppercase;
}

.img-10 {
  width: 10px;
}
.img-14 {
  width: 14px;
}
.img-16 {
  width: 16px;
}
.img-18 {
  width: 18px;
}
.img-18-25 {
  width: 18px;
  height: 25px;
}
.img-20 {
  width: 20px;
}
.img-22 {
  width: 22px;
}
.img-25 {
  width: 25px;
}
.img-32-32 {
  width: 32px;
  height: 32px;
}
.select-arrow {
  background-image: url("assets/images/down-arrow.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 11px !important;
  background-position: 95% 50% !important;
  padding-right: 20px;
}

.cell-req-awaiting {
  background: #d0e6ef;
  color: darkred;
  position: absolute;
  left: 0;
  top: 0;
  right: 1px;
  float: right;
  height: 100%;
  line-height: 36px;
  padding: 0 15px;
  text-align: center;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.req-awaiting {
  display: block;
  width: 100px;
  height: 26px;
  color: darkred;
}

/* color circles */
.green-circle,
.red-circle,
.gray-circle,
.orange-circle,
.white-circle,
.blue-circle,
.blue-eclipse-circle {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 15px;
  margin-top: 5px;
}

.green-circle {
  background: #7ed320 !important;
}

.red-circle {
  background: #d0011b !important;
}

.gray-circle {
  background: #a7b7c6 !important;
}

.orange-circle {
  background: #f5a623 !important;
}

.white-circle {
  background: #ffffff !important;
}

.blue-circle {
  background: #1a8bc3 !important;
}

.blue-eclipse-circle {
  border: 2px solid #1a8bc3;
}

.fetch-avail-refresh {
  background-image: url("./assets/images/refresh-button-blue.svg") !important;
  background-size: 11px;
  z-index: 999;
  border-radius: 50%;
  background-position: center;
  background-color: #fff !important;
  position: absolute;
  display: inline-block;
  left: 0;
  top: 0 !important;
  margin-top: -1px !important;
  margin: 0;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  transition-delay: ease 0.5ms all;
  // -webkit-animation: rotation 2s infinite linear;
}

.fetch-avail-refresh,
.fetch-avail-refresh-img {
  animation: rotation 0.6s infinite linear;
}

.fetch-rfp {
  animation: rotation 0.6s infinite linear;
}

.fetch-rfa {
  animation: rotation 0.6s infinite linear;
}

.fetch-avail-refresh-img {
  transition-delay: ease 0.5ms all;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

.red-color-txt {
  color: $red !important;
}

.pl-btn {
  background-color: #8ca0b3;
}

.pl-btn-dis {
  background-color: rgba(140, 160, 179, 0.44);
}

.txt-upper {
  text-transform: uppercase;
}

.f-11 {
  font-size: 12px;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.white-txt {
  color: #ffffff;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-16 {
  margin-top: 16px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-5 {
  margin-right: 5px;
}
.m-r-0 {
  margin-right: 0 !important;
}

.m-l-5 {
  margin-left: 5px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-lr-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.m-l-0 {
  margin-left: 0 !important;
}

.b-0 {
  border: 0 !important;
  box-shadow: none !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.white-bg {
  background-color: #ffffff;
}

input,
select,
textarea {
  font-family: $font-family;
}

/* color circles */

/* custom checkbox */

.cust-check {
  display: block;
  position: relative;
  padding-left: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  color: #000 !important;
  font-weight: 500;
  user-select: none;
  height: 15px;
  width: 15px;
  margin: 0;
}

.cust-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid;
  border-radius: 0;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.cust-check input:checked ~ .checkmark:after {
  display: block;
}

button.primary-btn {
  background: $button;
  border: 0;
  padding: 7px 20px;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  // box-shadow: $button-shadow;
  text-transform: uppercase;
  transition: 0.5s all;
  &.round-btn {
    border-radius: 10px;
  }
  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
}

.primary-btn:hover {
  // background: $button-blue-hover;
  background: $blue-solid;
  box-shadow: $button-shadow !important;
}

button.secondary-btn {
  background: transparent;
  color: $button-blue;
  padding: 5px;
  box-shadow: none;
  border: 0;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  border: 1px solid $button-blue;
  margin-left: 5px;
  padding: 6px 20px;
  border-radius: 3px;
  transition: 0.5s all;
  &:hover {
    background: $focus-color;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26) !important;
  }
  &.round-btn {
    border-radius: 10px;
  }
}

button.secondary-btn[disabled] {
  pointer-events: none;
}

.cust-check .checkmark:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 9px;
  border: solid;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* custom checkbox */

.sidebar-is-reduced.l-sidebar {
  overflow: visible;
}

.sidebar-is-reduced.sidebar-is-expanded .bs-tooltip-left,
body.sidebar-is-reduced.sidebar-is-expanded .bs-tooltip-right {
  display: none;
}

.sidebar-is-expanded .pl-menu > ul .pl-menu_item .pl-menu-item_expand {
  left: 0;
}

.sidebar-is-reduced.sidebar-is-expanded.l-sidebar {
  width: 256px;
}

.nav-close .pl-menu_item .pl-menu-item_title,
.hamburger-toggle.is-opened img {
  flex-basis: 100%;
  padding-right: 10px;
  position: relative;
  left: 220px;
  opacity: 0;
  width: 0;
  overflow: hidden;
  padding: 0;
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -ms-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}

.pl-menu_item .pl-menu-item_title,
.hamburger-toggle img {
  left: 0;
  opacity: 1;
  flex-basis: 100%;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  padding-right: 0;
  position: relative;
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -ms-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}

.pl-header-icon {
  position: relative;
  display: flex;
  float: left;
  min-width: 50px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  cursor: pointer;
}

.r-main {
  z-index: 0;
  margin-left: 135px;
  // margin-top: 58px;
  padding: 0 !important;
  width: calc(100% - 135px);
  height: calc(100vh - 62px);
  transition: 0.3s ease-in-out;
  background: #ffffff;
}

.r-main.sidebar-is-expanded {
  margin-left: 317px;
  margin-top: 0;
  padding: 0 !important;
  width: calc(100% - 317px);
  height: calc(100vh - 58px);
}

body.sidebar-is-reduced .r-main {
  padding-top: 58px;
  padding-left: 140px;
}

.r-main .content-wrapper {
  height: 100%;
}

.list-drop:before,
.noti-drop:before,
.info-drop:before {
  content: "\f107";
  position: absolute;
  top: -22px;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 22px;
  color: #9b9b9b;
}

.list-drop:before {
  left: 127px;
}

.noti-drop:before {
  left: 28px;
}

.info-drop:before {
  left: 78px;
}

.user-drop:before {
  left: 127px;
}

@media (max-width: 767.5px) {
  .l-header_inner {
    display: initial;
  }
  .menu-search.pl-search,
  .header-icons-group {
    /* border-bottom: 1px solid #d0d0d0; */
    border-left: 0;
  }
  .r-main {
    padding: 134px 0 0 70px;
  }
}

@media (min-width: 768px) {
  .pl-header-icon.search-save {
    margin-right: 50px;
  }
}

.ng-dropdown-panel {
  line-height: 1.25;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 6px;
  font-size: 13px;
  font-weight: 400;
  white-space: normal !important;
}

.ng-dropdown-panel {
  border-radius: 0;
  left: 0;
  padding: 0 0 0 0;
  height: auto;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: #ebf5ff;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #ebf5ff;
  color: #333;
}

.mat-mdc-form-field-flex {
  border: 1px solid #ccc;
  padding: 4px 10px;
  border-radius: 2px;
  height: 38px;
  background: white;
}
.mat-mdc-form-field-label {
  left: 0 !important;
  line-height: 20px !important;
  color: $black-secondary !important;
  font-size: 0.9em !important;
}

.mat-mdc-form-field-infix {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  top: 1px;

  label {
    left: -2px !important;
    overflow: visible;
  }

  .mat-mdc-form-field-label-wrapper {
    overflow: visible !important;
  }

  label:after {
    position: relative !important;
    margin-left: 4px !important;
  }
}

.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline,
svg.mat-datepicker-toggle-default-icon.ng-star-inserted path {
  display: none;
}

.mat-mdc-form-field-appearance-legacy.mat-mdc-form-field-can-float
  .mat-input-server:focus
  + .mat-mdc-form-field-label-wrapper
  .mat-mdc-form-field-label,
.mat-mdc-form-field-appearance-legacy.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float
  .mat-mdc-form-field-label {
  transform: translateY(0) !important;
  width: auto;
  background-color: #ffffff;
  font-family: $font-family;
  color: #7c7b80;
  font-size: 10px !important;
  text-shadow: 0 0 0;
  position: absolute;
  top: -3px;
  left: -4px !important;
  padding-left: 5px;
  font-family: $font-family;
}

.mat-button .mat-button-focus-overlay,
.mat-icon-button .mat-button-focus-overlay {
  opacity: 0 !important;
}

.mat-mdc-form-field-appearance .mat-mdc-form-field-suffix .mat-datepicker-toggle-default-icon,
.calender-icon {
  width: 16px !important;
  height: 20px;
  z-index: 99999;
  background-image: url("assets/images/calendar.svg");
  background-position: 15% 24%;
  background-size: 15px;
  background-repeat: no-repeat;
  background-color: #fff !important;
  opacity: 0.6;
}

span.calender-icon {
  height: 18px;
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: -1;
}

.mat-mdc-form-field-appearance .mat-mdc-form-field-infix {
  border: 0;
}

.form-group label {
  &.mdc-floating-label {
    margin-bottom: 0;
  }
}

.mat-mdc-form-field {
  display: block !important;
  .mat-mdc-text-field-wrapper {
    background: transparent !important;
    overflow: visible;
  }

  mat-label {
    background: white;
    padding: 0 4px;
  }
  mat-label:focus {
    opacity: var(--mat-form-field-hover-state-layer-opacity);
  }
}

.mat-mdc-form-field-focus-overlay {
  background-color: white !important;
}

/* ================================================================== */

/* Old IE styles
/* ================================================================== */

.mat-mdc-checkbox-background.mat-primary,
.mat-mdc-checkbox-frame {
  border-width: 1px !important;
  border-radius: 2px !important;
}

.mdc-checkbox-inner-container {
  height: 15px !important;
  width: 15px !important;
  margin-right: 4px !important;
}

.mdc-checkbox {
  padding: 4px;
  .mdc-label {
    font-family: $font-family;
    font-size: 12px;
  }

  .mat-mdc-checkbox-touch-target {
    height: 18px !important;
    width: 18px !important;
  }

  .mdc-checkbox__native-control {
    height: 18px !important;
    width: 18px !important;
    top: 0px !important;
    left: 0px !important;
  }
  .mdc-checkbox__background {
    border-width: 1px;
  }

  .mat-ripple.mat-mdc-checkbox-ripple.mat-mdc-focus-indicator {
    display: none;
  }
}

app-checkbox {
  .mat-mdc-checkbox {
    .mdc-label {
      padding-bottom: 0px;
      margin-bottom: 0px;
      padding-left: 0px;
      margin-left: 0px;
    }
    .mat-ripple.mat-mdc-checkbox-ripple.mat-mdc-focus-indicator {
      display: none;
    }
    .mdc-checkbox__background {
      border-width: 1px;
    }
  }
}

/* Progree bar*/

.ng-progress {
  position: relative !important;
}

.bar-shadow {
  box-shadow: none !important;
}

.ng-spinner {
  display: none !important;
}

.bar {
  background-color: $color-primary !important;
  height: 3px;
}

.sub-title span,
.sub-title-100 {
  font-size: 10px;
  font-weight: 500;
  border-bottom: 2px solid $color-primary;
  padding-bottom: 2px;
}

.mdc-checkbox__ripple {
  display: none;
}

/* campaign planner */
.mat-select-arrow {
  width: 15px !important;
  height: 15px !important;
  border: 0 !important;
  background: url(assets/images/down-arrow.svg);
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 3px 3px;
  opacity: 0.5;
  margin: 0 !important;
}

.mat-mdc-select-value,
.mat-input-element {
  font-size: 12px !important;
  color: #464a4c;
  font-family: $font-family;
}

/* error message */
span.error-msg {
  position: absolute;
  top: 39px;
  left: 0;
  font-size: 10px;
  color: $red;
}

span.error-msg-relative {
  position: relative;
  color: $red;
  font-size: 10px;
  left: 5px;
}

.inflight-schedule-error {
  position: absolute;
  width: 300px;
}

.mat-mdc-form-field-flex .error-msg {
  left: -10px !important;
}

.custom-select.is-invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.was-validated .form-control:invalid:focus,
.custom-select.is-invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.was-validated .form-control:invalid,
.mat-mdc-form-field.mat-mdc-form-field-invalid .mat-mdc-form-field-label {
  box-shadow: none !important;
  border-color: rgba(0, 0, 0, 0.15);
  color: $black-secondary !important;
}

.mdc-form-field > label {
  margin-bottom: 0 !important;
}

.mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
  color: $dark-red;
}

.red-star {
  color: $red;
}

/* error message */

.poi-logo-icon:before {
  content: " ";
  display: block;
  position: absolute;
  height: 22px;
  width: 22px;
  background-image: url("assets/images/pin-2.svg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.poi-logo-icon {
  height: 22px;
  width: auto;
}

.map-view-footer .showing-list {
  margin-bottom: 0 !important;
  margin-right: 12px !important;
  margin-top: 0 !important;
}
/* dailog box (Modal) */
.mat-dialog-content {
  h4 {
    font-size: 12px;
    font-weight: 500;
    font-family: $font-family;
    img {
      margin-right: 5px;
    }
  }
}
/* ddailog box (Modal) */

/*table no data */
.table-nodata {
  background-repeat: no-repeat;
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  .table-nodata-content {
    position: absolute;
    transform: translate(-50%, -50%);
    text-align: center;
    h2 {
      font-size: 12px;
      margin-bottom: 20px;
      font-weight: 600;
    }
    p {
      font-size: 12px;
      font-weight: 500;
    }
  }
}
/* table no dta */
.mat-drawer-container {
  float: right;
  position: absolute !important;
  top: 0;
  right: 0;
  background: transparent !important;
  .mat-drawer-content {
    transform: translate(0%, 40%);
    overflow: visible !important;
    width: 25px;
    background: #013f65;
    color: $white-color;
    transform: rotate(0deg);
    box-shadow: 2px 0 11px #a1a1a1;
    margin-left: 4px;
    cursor: pointer;
    .sidenav-title {
      transform: rotate(270deg);
      p {
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 400;
        margin: 0;
        line-height: 24px;
        display: inline-block;
        position: relative;
        left: 50%;
        right: 50%;
        text-align: center;
        height: 25px;
        width: 223px;
        background-repeat: no-repeat;
        background-size: 11px;
        background-position: 0 48%;
        transition: 0.5s ease;
        img {
          width: 13px;
          margin-left: 5px;
          opacity: 0.7;
          margin-top: -3px;
        }
        .mdc-checkbox-inner-container {
          visibility: hidden;
        }
      }
    }
  }
}

/* mat select */
.mat-mdc-option {
  font-family: $font-family;
  line-height: 36px !important;
  min-height: 36px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;

  &.mat-active {
    background: $focus-color !important;
  }
  &.mat-active.mat-option.mat-selected:not(.mat-option-disabled) {
    color: $color-primary;
  }
  &.mat-selected:not(.mat-option-disabled) {
    background: $focus-color !important;
    color: $color-primary !important;
  }
  .mdc-list-item__primary-text {
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .mat-select-search-inner {
    &.mat-tab-header {
      border-color: $color-primary !important;
    }
    .mat-icon-button {
      width: 25px;
      height: 25px;
      line-height: 25px;
      img {
        width: 10px;
        opacity: 0.8;
      }
    }
  }
}
.mat-selected {
  background: $focus-color;
  color: #09a9fa;
}

.mat-mdc-form-field-disabled,
.mat-select-disabled {
  cursor: not-allowed;
}

.mat-mdc-option .mat-pseudo-checkbox-minimal {
  margin-left: 4px !important;
}
/*mat select*/

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  padding: 6px 0 6px 1px;
  text-transform: uppercase;
  font-weight: 600;
  background-color: #f3fdff;
  padding-left: 15px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup ~ .ng-option {
  padding-left: 15px;
}

.blink {
  text-decoration: blink;
  -webkit-animation-name: blinker;
  -webkit-animation-duration: 0.6s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}
/*** Dragula Drag & Drop ***/

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  &::after {
    content: "";
    background-image: url("./assets/images/scroll-arrows.svg");
    background-size: 14px 14px;
    background-repeat: no-repeat;
    display: block;
    height: 14px;
    width: 14px;
    position: absolute;
    top: 2px;
    opacity: 0.2;
    left: -20px;
  }
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  .group-title {
    display: none !important;
  }
  .group-items {
    border: 1px dashed #9fcbce !important;
  }
}

/*** Complete of Drag & Drop ***/
.mat-spinner,
.mat-spinner svg {
  width: 30px;
  height: 30px;
  display: inline-block;
}

.mat-progress-spinner svg {
  position: relative !important;
  padding: 5px;
}

/*icon-color */
.icon-color {
  filter: invert(39%) sepia(76%) saturate(518%) hue-rotate(154deg) brightness(88%) contrast(95%);
  &:hover {
    filter: invert(24%) sepia(99%) saturate(507%) hue-rotate(158deg) brightness(93%) contrast(94%);
  }
}

.mat-mdc-menu-panel {
  top: 1px !important;
  position: relative;
  border: 1px solid $color-primary;
  .mat-mdc-menu-content {
    //background: #f9feff;
    .mat-mdc-menu-item {
      font-size: 14px;
      min-height: 36px !important;
    }
  }
}

.campaign-pack {
  .datatable-wrapper,
  .ngx-datatable.material {
    height: 130px !important;
    width: calc(100% - 45px);
  }
}
.option-dialog {
  .mat-mdc-dialog-container {
    width: 550px;
    .ngx-datatable.material {
      height: auto !important;
      min-height: 180px;
      .datatable-body {
        min-height: 180px;
      }
    }
    .datatable-wrapper {
      height: auto !important;
      width: 100% !important;
    }
    h4 {
      font-size: 15px;
      line-height: 20px;
      color: $color-primary;
      em {
        color: $color-secondary;
      }
    }
    p {
      margin-top: 10px;
      color: $color-primary;
    }
    .order-sec {
      text-align: right;
      margin-bottom: 10px;
      color: $color-primary;
      input {
        border-color: $color-primary;
        padding: 5px;
      }
    }
  }
}

/* error dialog */
.error-dialog-container {
  .mat-dialog-container {
    width: 500px;
    overflow: hidden;
    background: $error-notification;
    .add-camp-error {
      margin-top: 0;
    }
    h6 {
      margin-bottom: 20px;
    }
    .flex {
      display: flex;
    }
    ul {
      display: flex !important;
      min-width: 30px;
      font-size: 13px;
      &.display-initial {
        display: initial !important;
      }
    }
  }
}
.date-picker {
  height: calc(2.25rem + 2px);
  background-color: transparent !important;
}
/* Data Table */
.ngx-datatable {
  .datatable-header .datatable-header-cell .sort-btn {
    position: absolute;
    top: 8px;
    right: 0px;
    font-size: 17px;
  }
  .datatable-icon-sort-unset {
    display: none !important;
  }
  &.material .datatable-header .datatable-header-cell.sort-active {
    font-weight: 600;
  }
}
.datatable-header {
  border-bottom: 0 !important;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.24);
  z-index: 99;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  .datatable-header-inner,
  .datatable-row-right {
    background-color: $table-head-color !important;
    width: 100% !important;
  }
  .datatable-header-cell {
    // border-right: 0.5px solid rgba(0, 0, 0, 0.15);
    font-weight: 400;
    background-color: $table-head-color !important;
    vertical-align: inherit;
    padding: 0.4rem !important ;
    font-size: 12px !important;
    text-transform: uppercase;
    color: $blue-solid !important ;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 23px !important;
    font-family: $font-family;
    &:first-child {
      padding: 6px !important;
    }
    mdc-checkbox {
      display: block;
      height: 23px;
      label {
        margin-bottom: 5px !important;
      }
    }
    .resize-handle {
      border-right: solid 1px #d6d4d4 !important;
    }
  }
}
.table-striped tbody tr:nth-of-type(odd),
.datatable-row-even {
  background-color: $table-row-even-color !important;
}
.datatable-body {
  .datatable-body-cell {
    padding: 0.4rem !important;
    font-size: 13px !important;
    min-height: 35px !important;
    vertical-align: middle !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    line-height: 23px !important;
    font-family: $font-family;
    &:first-child {
      padding: 6px !important;
    }
    mat-mdc-checkbox {
      display: block;
      height: 15px;
      label {
        margin-bottom: 0 !important;
      }
    }
  }
  .datatable-row-wrapper:nth-of-type(odd) .datatable-row-right {
    background-color: inherit !important;
    @at-root #{selector-replace(&, '.datatable-row-wrapper:nth-of-type(odd)', '.datatable-row-wrapper:nth-of-type(odd):hover')} {
      background-color: $table-row-hover !important;
      background: $table-row-hover !important;
    }
  }
}
.datatable-body::-webkit-scrollbar:vertical {
  background: #e2f8fc;
}
.search-snackbar {
  background: $color-primary;
  color: $white-color;

  i {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  ul {
    padding: 0;
    margin: 0 0 0 10px;
  }
}
.mat-snack-bar-container.search-snackbar {
  min-width: 265px !important;
  position: absolute;
  left: 0;
  bottom: 0;
  background: $color-secondary;
}

.aat-error-snackbar {
  background: $color-primary;
  color: $white-color;

  i {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  ul {
    padding: 0;
    margin: 0 0 0 10px;
  }
}

.mat-snack-bar-container.aat-error-snackbar {
  min-width: 265px !important;
  background: $color-secondary;
}

/* custom loader */
.custom-loader {
  background: url("assets/images/loader.gif");
  background-size: 100px;
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: 9999;
  text-align: center !important;
  background-repeat: no-repeat;
}

/* sticky table */
.sticky-table {
  table {
    table-layout: fixed;
    width: 100%;
    td,
    th {
      width: 100px;
      &:last-child {
        position: sticky;
        right: 0;
        z-index: 1;
        background: #f9f9f9;
      }
    }
    thead {
      position: sticky;
      z-index: 1;
      top: 0;
      th {
        position: sticky;
        top: 0;
      }
    }
  }
}

.table-cell-edit {
  &::after {
    content: "";
    background: url("./assets/images/pencil-edit.png") no-repeat;
    position: absolute;
    top: 5px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    opacity: 0.3;
    margin-left: 10px;
    background-size: 10px;
  }
  position: relative;
  padding-left: 0;
  // border: 1px dotted #e0dddd;
  &:hover {
    &::after {
      opacity: 1 !important;
    }
  }
  .input-error {
    border-color: $red !important;
  }
  input {
    height: 27px;
    max-width: 48px !important;
  }
}

.table-cell-input input {
  width: 100%;
}

/* grid-expansion */
.grid-expansion {
  padding: 0 !important;
  border-radius: 0 !important;
  box-shadow: $color-secondary-shadow;
  mat-expansion-panel-header {
    padding: 0 !important;
    position: relative;
    cursor: auto;
    span.mat-expansion-indicator {
      background-image: url("./assets/images/expansion-down-arrow.svg");
      background-repeat: no-repeat;
      background-size: 18px;
      position: absolute;
      left: 10px;
      top: 8px;
      width: 18px;
      height: 18px;
      z-index: 99;
      cursor: pointer;
      &::after {
        border-style: none;
      }
    }
  }
  .mat-expansion-panel-body {
    padding: 0 !important;
  }
  .mat-expanded span.mat-expansion-indicator {
    transform: rotate(180deg);
    transition: 0.5ms ease;
  }
}

.bg-search {
  input {
    background-image: url("./assets/images/search.svg") !important;
    background-repeat: no-repeat !important;
    background-size: 16px !important;
    background-position: 98% 50% !important;
  }
}

.tool-tip,
.mat-tooltip {
  background-color: #e5f8fc !important;
  color: $grid-font !important;
}

.upload-sitelist-response-dialog {
  .mat-dialog-container {
    height: 300px;
    width: 400px;
  }
}

.popover {
  background: $popover-triangle;
  border: 1px solid #d8d3d3;
  box-shadow: 0 2px 5px #797979;
  max-width: none !important;
  padding: 0;
  &::before {
    display: none !important;
  }
  &::after {
    display: none !important;
  }
}

.popover-body {
  padding: 10px !important;
}

.bs-popover-top .popover-arrow {
  &::before {
    border-top-color: #d8d3d3;
  }
  &::after {
    border-top-color: $popover-triangle;
  }
}

.bs-popover-bottom .popover-arrow {
  &::before {
    border-bottom-color: #d8d3d3;
  }
  &::after {
    border-bottom-color: $popover-triangle;
  }
}

.bs-popover-left .popover-arrow {
  &::before {
    border-left-color: #d8d3d3;
  }
  &::after {
    border-left-color: $popover-triangle;
  }
}

.bs-popover-right .popover-arrow {
  &::before {
    border-right-color: #d8d3d3;
  }
  &::after {
    border-right-color: $popover-triangle;
  }
}

.ql-tooltip.ql-editing {
  margin-left: 113px;
}

.impacts-in-words {
  font-size: 13px;
  color: #222222;
}

// Mat Radio Button

.mat-radio-button {
  &.mat-radio-checked,
  &.mat-primary.mat-radio-checked {
    color: $radio-checked;
    .mat-radio-outer-circle {
      border-color: $radio-circle;
    }
    .mat-radio-inner-circle {
      background-color: $radio-circle;
    }
    .mat-mdc-radio-button .mdc-label {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  &.mat-accent {
    padding-left: 10px;

    .mat-radio-label {
      margin-bottom: 0;
    }

    .mat-radio-inner-circle {
      background-color: $radio-circle;
    }

    &.mat-radio-checked .mat-radio-outer-circle {
      border-color: $radio-circle;
    }
  }
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.mat-mdc-slide-toggle.mat-checked .mat-mdc-slide-toggle-thumb {
  background-color: #1777a7;
}

.mat-mdc-slide-toggle.mat-checked .mat-mdc-slide-toggle-bar {
  background-color: #93ceec;
}

.mdc-line-ripple::before,
.mdc-line-ripple::after {
  border-bottom-style: none !important;
}

.mat-mdc-form-field-focus-overlay {
  border: solid 1px lightgrey;
  opacity: 1;
}

.mdc-text-field {
  padding: 1px !important;
}

// .cdk-overlay-container {
//   position: fixed;
//   z-index: 2000;
//   pointer-events: none;
//   top: 0;
//   left: 0;
//   height: 100%;
//   width: 100%;

//   .cdk-overlay-pane {
//     position: absolute;
//     pointer-events: auto;
//     box-sizing: border-box;
//     display: flex;
//     max-width: 100%;
//     max-height: 100%;
//   }

//   .cdk-overlay-connected-position-bounding-box {
//     position: absolute;
//     display: flex;
//     flex-direction: column;
//     min-width: 1px;
//     min-height: 1px;
//   }
// }

.cdk-describedby-message-container.cdk-visually-hidden {
  display: none;
}

.add-camp-error {
  .list-inline {
    display: inline-block;
    margin: 4px !important;
  }
}

.form-control[readonly] {
  background-color: #fdfdfd !important;
}

.region-mapbox-popup {
  margin-top: 10px;
}

.mapboxgl-popup-content {
  .frameDetails {
    border: none;
  }

  .mapboxgl-popup-close-button {
    right: 7px;
    top: 3px;
    font-size: 2em;
  }
}
