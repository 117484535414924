@import "src/theme-styles.scss";
bs-datepicker-container,
bs-daterangepicker-container {
  margin-left: 300px;
  .bs-datepicker-head {
    background-color: $lite-blue-color !important;
  }
  td {
    color: $black-color !important;
  }
  .selected {
    background-color: $lite-blue-color !important;
  }
  th {
    color: $lite-blue-color !important;
    font-size: 12px !important;
  }
  .days {
    span {
      width: 30px !important;
      height: 30px !important;
      line-height: 30px !important;
      font-size: 12px !important;
    }
  }
  .bs-datepicker-head {
    min-width: 270px;
    height: 40px;
    padding: 5px;
  }
  .bs-datepicker-container {
    padding: 5px !important;
    min-height: 342px;
  }
  .bs-datepicker-body {
    min-height: 291px;
    min-width: 270px;
    thead {
      margin-bottom: 10px !important;
    }
    tbody {
      tr {
        &:first-child,
        td {
          position: relative;
          top: 7px;
          margin-top: 10px !important;
        }
        &:nth-child(odd) {
          background: inherit !important;
        }
      }
    }
    .bg-info {
      background-color: transparent !important;
      border: 1px solid $color-primary !important;
      font-weight: 600;
      width: 25px !important;
      height: 25px !important;
      line-height: 24px !important;
      &.selected {
        background-color: $color-primary !important;
        width: 30px !important;
        height: 30px !important;
        line-height: 30px !important;
      }
    }
    .is-other-month {
      display: none !important;
    }
  }
}
