@import "src/theme-styles.scss";
datatable-footer,
.datatable-footer {
  .datatable-footer-inner {
    height: 30px !important;
    background: $pagination-bg;
    .datatable-footer {
      border: 0 !important;
      p {
        margin-bottom: 0;
        font-size: 13px;
        color: $color-primary;
        padding-left: 6px;
      }
    }
    .datatable-pager {
      text-align: center !important;
      ul {
        li {
          color: $pagination-font;
          i {
            color: $pagination-font;
            line-height: 28px !important;
            padding: 0 !important;
          }
          &.disabled {
            opacity: 0.5;
          }
          a {
            color: $pagination-font;
            width: 24px;
            padding: 0 !important;
            &:hover {
              color: $pagination-font;
              background: $pagination-hover-bg;
              border-radius: 50%;
            }
          }
          &.active {
            a {
              background-color: $pagination-active-bg !important;
              color: $pagination-active-font;
              border-radius: 50%;
              &:hover {
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}
