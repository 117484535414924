@import "src/theme-styles.scss";

button.primary-btn,
.primary-btn {
  background: $button;
  border: 0;
  padding: 7px 20px;
  margin-left: 5px;
  border-radius: 3px;
  color: $white-color;
  font-weight: 500;
  font-size: 12px;
  // box-shadow: $button-shadow;
  text-transform: uppercase;
  transition: 0.5s all;
  &.round-btn {
    border-radius: 20px;
  }
  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
}

button.secondary-btn,
.secondary-btn {
  background: transparent;
  color: $button-blue;
  padding: 5px 10px;
  box-shadow: none;
  border: 0;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  border: 1px solid $button-blue;
  margin-left: 5px;
  padding: 6px 20px;
  border-radius: 3px;
  transition: 0.5s all;
  &:hover {
    background: $focus-color;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  }
  &.round-btn {
    border-radius: 20px;
  }
  &[disabled] {
    border-color: gray;
    color: gray;
    opacity: 0.5;
    cursor: default;

    &:hover {
      background: $focus-color;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.26);
    }
  }
}

.filters-button,
.reset-button {
  font-size: 12px;
  border: 1px solid $filter-btn-border !important;
  color: $filter-btn-color;
  background-color: $white-color;
  border-radius: 5px !important;
  text-transform: uppercase;
  font-weight: normal;
  display: flex !important;
  align-items: center;
  line-height: 27px !important;
  height: 28px;
  padding: 0px 10px !important;
  i {
    color: #9b9b9b;
    width: 15px;
    position: relative;
    right: 2px;
  }
}

.grid-btn,
.btn {
  border: 1px solid $color-primary;
  border-radius: 5px;
  background: #fff;
  text-transform: uppercase;
  text-transform: uppercase;
  display: block;
  line-height: 19px !important;
  padding: 3px 10px;
  color: $color-primary;
  margin-top: 2px;

  &.cursor-disabled {
    filter: invert(0%) sepia(12%) saturate(7%) hue-rotate(129deg) brightness(100%) contrast(97%);
    background-color: transparent !important;

    &:hover {
      background-color: transparent !important;
    }
  }

  .cursor-disabled {
    filter: invert(0%) sepia(12%) saturate(7%) hue-rotate(129deg) brightness(70%) contrast(50%);
    background-color: transparent !important;

    &:hover {
      background-color: transparent !important;
    }
  }

  &:hover {
    background-color: $color-primary;
    color: #ffffff !important;

    span {
      color: #ffffff !important;
    }
  }

  button {
    line-height: 21px !important;
    padding: 0 !important;
    font-weight: 600;
  }

  .remove-img {
    display: none;
  }
}
