@import "src/theme-styles.scss";
// mat-dialog-container {
//   margin: 0px !important;
//   padding: 0px !important;
//   position: fixed !important;
//   top: 0 !important;
//   left: 0 !important;
//   display: flex !important;
//   max-width: 100% !important;
//   min-width: 80% !important;
//   background-color: #0000004d;

//   .mdc-dialog__surface {
//     padding: 15px !important;
//     height: auto !important;
//     overflow: hidden;
//   }

//   .mat-mdc-dialog-title {
//     font-size: 13.5px;
//     font-family: "Source Sans Pro";
//     font-weight: 500;
//     padding-bottom: 6px;
//     text-transform: uppercase;
//     color: $color-primary;
//     margin-bottom: 10px;
//     margin-top: -4px;
//     margin-right: 48px;
//     // border-bottom: solid 1px lightgray;
//     img {
//       margin-right: 5px;
//     }
//   }

//   mat-dialog-content {
//     // border-bottom: 1px solid $color-primary;
//     // box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.26);
//     margin: 0;
//     padding: 0;
//     padding-bottom: 10px;
//     max-height: 84vh !important;

//     ul {
//       display: flex;
//       font-family: "Source Sans Pro" !important;
//       font-size: 13px;

//       li {
//         &:first-child {
//           margin-right: 0px !important;

//           &img {
//             padding-top: 5px;
//           }
//         }

//         img {
//           margin-right: 10px;
//         }
//       }
//     }

//     &.error-dialog {
//       &::before {
//         content: "";
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         background: #fce8e9;
//         padding: 0;
//         z-index: -1;
//         top: 0;
//         left: 0;
//       }
//     }
//   }

//   .mat-mdc-dialog-actions {
//     margin-bottom: -10px;
//     border-top: solid 1px lightgray;
//   }

//   .modal-foo-btns {
//     width: 100%;
//   }
// }

.mat-mdc-dialog-container {
  .mat-dialog-title,
  .mat-mdc-dialog-title {
    padding: 0;
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid $color-primary;
    img {
      margin-right: 12px;
    }
  }

  .mat-mdc-dialog-title::before {
    display: none !important;
  }

  .mat-mdc-dialog-surface {
    // padding: 24px;
  }

  .mat-mdc-dialog-content {
    max-height: 100% !important;
  }
}

.free-form-dialog {
  .mdc-dialog__surface {
    padding: 0;
  }
}

.dialog-content {
  border-bottom: 1px solid $color-primary;
  box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.26);
  padding-bottom: 10px;
}

.dialog-action {
  padding: 10px 0;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -12px;
}

.modal-close {
  z-index: 1000;
  position: relative;
  right: 36px;
  top: 4px;
}
