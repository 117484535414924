.status-cell {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 5px 15px;
  color: $color-primary;
  text-align: center;
  border-bottom: 0px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: initial;
  color: #000;
  font-size: 12px;
  text-transform: uppercase;
  background: #f8fafb;
}

.search-add {
  a {
    color: inherit;
  }
}

.planned-status {
  //background: #BEC1C3;
  border-left: 10px solid #bec1c3;
}
.prepared-for-option {
  //background-color: #58D5F3;
  border-left: 10px solid #58d5f3;
}
.optioned-status {
  //background-color: #1DB5E7;
  border-left: 10px solid #1db5e7;
}
.booked-status {
  //background: #00AE37;
  border-left: 10px solid #00ae37;
}
.cancelled-status {
  //background-color: #004063;
  border-left: 10px solid #004063;
}
.partially-prepared-for-option {
  //background-color: #58D5F3;
  border-left: 10px solid #58d5f3;
}
.partially-booked-status {
  //background-color: #7DD94B;
  border-left: 10px solid #7dd94b;
}
.partially-optioned-status {
  //background-color: #1DB5E7;
  border-left: 10px solid #1db5e7;
}
.partially-cancelled-status {
  //background-color: #004063;
  border-left: 10px solid #004063;
}
.awaiting-booking {
  //background-color: #C5D902;
  border-left: 10px solid #c5d902;
}
.awaiting-cancellation {
  //background-color: #F3A67D;
  border-left: 10px solid #f3a67d;
}
.awaiting-optioning {
  //background-color: #FCDA2B;
  border-left: 10px solid #fcda2b;
}
.awaiting-option-amendment {
  border-left: 10px solid #fcda2b;
  padding: 3px 4px;
  font-size: 11.5px;
}
.awaiting-booking-amendment {
  border-left: 10px solid #fcda2b;
  padding: 1px 1px;
  font-size: 11px;
}
.partially-awaiting-booking:before {
  background-color: #00e800;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.25) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.25) 50%,
    rgba(255, 255, 255, 0.25) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1.5rem 1.5rem !important;
  content: "";
  position: absolute;
  width: 10.5px;
  height: 100%;
  left: 0;
  bottom: 0px;
}
.partially-awaiting-cancellation:before {
  background-color: #898989; //strip color #898989
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1.5rem 1.5rem !important;
  content: "";
  position: absolute;
  width: 10.5px;
  height: 100%;
  left: 0;
  bottom: 0px;
}
.partially-awaiting-optioning:before {
  background-color: #2bf3fd; //strip color #2BF3FD
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.25) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.25) 50%,
    rgba(255, 255, 255, 0.25) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1.5rem 1.5rem !important;
  content: "";
  position: absolute;
  width: 10.5px;
  height: 100%;
  left: 0;
  bottom: 0px;
}
