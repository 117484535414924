//Theme styles
// @import "assets/fonts/style.css";
// $font-family:'Montserrat', sans-serif !important;
$font-family: "Source Sans Pro" !important;
$font-11: 11px;
$font-12: 12px;
$font-13: 13px;
$font-14: 14px;

$pad-0: 0px;
$pad-5: 5px;
$pad-10: 10px;
$mar-0: 0px;
$mar-5: 5px;
$mar-10: 10px;
$border-0: 0px;

$color-success: #15a628;
$color-error: #be4a3b;
$color-warning: #f5a623;
$color-primary: #1777a7;
$color-secondary: #f49290;
$button: #1777a7;
$color-sidenav: #fdfdfd;
$button-orange: #f5a623;
$breadcrumb-color: #1777a7;
$error-notification: #fce8e9;
$success-notification: #f1fffc;
$plato-black: #222222;

$color-secondary-shadow: 0 2px 0px -1px rgba(0, 0, 0, 0.24);
$button-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);

//icon
$icon-primary: $color-primary;
$icon-error: $color-error;
$icon-warning: $color-warning;

//Buttons
$button-blue: #1777a7;
$button-blue-hover: #105578;
$button-gray: #a6aaa9;
$button-gray-hover: #999b9a;
//custom table
$table-border: #e4f4f9;
$table-head-color: #e1f8fc;
$table-head-primary: #1777a7;
$table-head-primary-font: #ffffff;
$table-head-secondary: #d8d9db;
$table-head-secondary-font: #5a6067;
$table-row-even-color: #ffffff;
$table-row-odd-color: #f9f9f9;
$table-row-hover: #f4f9fb;
$table-row-hover-border: #e4f2ff;
$table-row-active: #eff5f9;
$table-fixed-head-color: #dff6ff;
$table-head-font-color: #126893;
$table-row-expanded: #dddee0;

$font-caps: uppercase;
$weight-600: 600;
$lite-blue-color: #00739a;
$blue-secondary: #006b98;
$black-color: #000;
$black-secondary: #484848;
$white-color: #fff;
$gray-color: #ababab;
$orange: #f49017;
$yellow: #f6d228;
$purple: #b36ae3;
$green: #0f9351;
$blue-solid: #136691;
$red: #ec5d57;
$dark-red: #f30a00;
$gray-font: #9d9d9d;
$pipe-color: #cccccc;
$focus-color: rgba(176, 234, 245, 0.1);
$focus-hexa: #f7fdfe;
$campaign-view-background: #fafafa;

$transparent-color: transparent;
$border-2px: 2px;
$border-transparent-2px: 2px solid transparent;
$transition-smooth: 0.5s ease;

//Campaign Grid
$cg-header-bg: #fff;
$cg-header-txt: #216d95;
$cg-dialog-th-bg: #d8d9db;
$cg-dialog-th-txt: #64696f;
$cg-dialog-td-txt: #144b6d;
$cg-table-border: #fafcfe;
$cg-chkbox-border: #77a9c7;
$cg-toggle-switch-bg: #77a9c7;
$cg-toggle-switch-thumb: #1777a7;
$unit-price-color: #538812;
$unit-cost-color: #1777a7;

$grid-font: #51555e;
$grid-border: #e4e2e2;

//table pagination
$pagination-bg: #e1f8fc;
$pagination-font: #1f78a5;
$pagination-hover: #1f78a5;
$pagination-hover-bg: #1f78a5;
$pagination-active-font: #ffffff;
$pagination-active-bg: #1777a7;

//ng select
$ng-select-bg: #fff;
$ng-select-border: #c7dcfe;
$ng-select-close: #f69f42;
$ng-select-focus: #f3fdff;
$ng-select-focus-border: #d0d0d0;
$ng-select-disable: #f1f1f1;

//filter-btn
$filter-btn-border: #9b9b9b;
$filter-btn-color: #333333;
$popover-triangle: #ffffff;

//Radio button
$radio-checked: #666a70;
$radio-circle: rgba(0, 0, 0, 0.54);

//Form
$form-section-title: #006b98;
