:root {
  $primary-color: #1a8bc3;
  $label-color: #484848;

  --mdc-checkbox-state-layer-size: 24px;
  --mdc-icon-button-state-layer-size: 24px;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-text-button-container-height: 28px;
  --mdc-filled-button-container-height: 28px;
  --mdc-outlined-button-container-height: 28px;
  --mdc-protected-button-container-height: 28px;
  --mat-form-field-container-height: 28px;
  --mdc-icon-button-icon-size: 16px;
  --mdc-checkbox-disabled-unselected-icon-color: gray;

  // --mdc-text-button-label-text-color: #1a8bc3;
  // --mdc-radio-selected-focus-icon-color: #1a8bc3;
  // --mdc-radio-selected-hover-icon-color: #1a8bc3;
  // --mdc-radio-selected-icon-color: #1a8bc3;
  // --mdc-radio-selected-pressed-icon-color: #1a8bc3;
  // --mat-radio-checked-ripple-color: #1a8bc3;
  // --mat-select-arrow-transform: 0px;
  // --mdc-checkbox-selected-icon-color: #1a8bc3;
  // --mdc-checkbox-selected-hover-icon-color: #106b98;
  // --mdc-checkbox-selected-focus-icon-color: #1a8bc3;
  // --mdc-checkbox-selected-pressed-icon-color: #106b98;
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mat-select-arrow-transform: translateY(0px);
  --mat-select-invalid-arrow-color: rgba(0, 0, 0, 0.54);

  --mdc-filled-text-field-error-label-text-color: $label-color;
  --mdc-filled-text-field-error-hover-label-text-color: $label-color;
  --mdc-filled-text-field-error-focus-label-text-color: $label-color;
  --mdc-filled-text-field-error-caret-color: $label-color;
  --mdc-filled-text-field-error-active-indicator-color: $label-color;
  --mdc-filled-text-field-error-focus-active-indicator-color: $label-color;
  --mdc-filled-text-field-error-hover-active-indicator-color: $label-color;
  --mdc-slider-active-track-color: transparent;
}
