.notification {
  position: absolute;
  background: #fff;
  border-radius: 5px;
  padding: 13px;
  z-index: 999;
  box-shadow: 0px 2px 5px #797979;
  padding: 0;
  border: 1px solid $color-primary;
  &:after {
    content: "";
    position: absolute;
    border-right: 15px solid transparent;
    border-bottom: 15px solid $color-primary;
    border-left: 15px solid transparent;
    right: 10px;
    top: -16px;
    z-index: -1;
  }
  &:before {
    content: "";
    position: absolute;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #fff;
    border-left: 15px solid transparent;
    right: 10px;
    top: -15px;
  }
}
.notification-header {
  padding: 13px;
  box-shadow: 0px 0px 3px #ccc;
  h5 {
    font-size: 13px;
    color: $color-primary;
    text-transform: uppercase;
    margin: 0;
  }
}

.notification-content {
  max-height: 400px;
  overflow: hidden;
  overflow-y: scroll;
  margin-right: 6px;
  margin-top: 10px;
  ul {
    padding: 0;
    padding-right: 4px;
    li {
      border-bottom: 1px solid $color-secondary;
      padding-bottom: 10px;
      padding: 10px;
      margin-top: 0;
      padding-right: 0;

      label {
        margin-bottom: 0;
      }
      .notification-title {
        font-size: 13px;
        color: $color-primary;
      }
      .notification-owner {
        text-align: right;
        color: $color-primary;
        font-size: 12px;
        margin-bottom: 5px;
      }
      .notification-body {
        padding: 5px;
        font-size: 13px;
      }
    }
  }
  .custom-loader {
    top: 85% !important;
    background-size: 50px;
    width: 50px;
  }
}

.clickable {
  cursor: pointer;
}

.notification-footer {
  padding-top: 7px;
  text-align: center;
}
