@import "src/theme-styles.scss";

.mat-mdc-chip-grid {
  display: block !important;
  border: 1px solid lightgray;
  background: #fff;
  min-height: 100px;
  max-height: 250px;
  overflow-x: hidden;
  width: 100%;
  margin: 0 0 15px 0 !important;
  padding: 5px 10px !important;

  .mat-mdc-chip-input {
    border: none;
  }
}

.mat-mdc-chip-grid,
.chips-wrapper {
  .mat-mdc-chip {
    height: auto;
    min-height: auto;
    border: 1px solid #c7dcfe;
    padding: 0 0 0 3px !important;
    font-size: 11px;
    font-family: Roboto, "Helvetica Neue", sans-serif; /*Matching old font-family styles from tag-input library*/
    letter-spacing: 0.8px;
    margin: 2% 2% 2% 0% !important;
    color: #000 !important;
    font-weight: 500;
    border-radius: 0;
    background: #fff !important;
    line-height: 25px;
    &:hover {
      background: none;
      -webkit-box-shadow: 0 2px 3px 1px #d4d1d1;
      box-shadow: 0 2px 3px 1px #d4d1d1;
    }
    &::after {
      background: none;
    }
    delete-icon {
      display: inline;
      cursor: pointer;
      opacity: 1 !important;
      &:hover {
        -webkit-transform: initial;
        transform: initial;
      }
      svg {
        position: absolute;
        top: 2px;
        right: 1px;
        height: 22px;
        width: 14px;
        path {
          fill: #f69f42 !important;
        }
      }
    }
    &.invalid-poi-chip {
      border: 2px solid #ff6347 !important;
      color: #ff6347 !important;
      line-height: 24px;
    }

    .mat-mdc-chip-remove {
      background: url("../../../../assets/images/close-orange.svg") no-repeat;
      background-size: 100%;
      width: 10px;
      height: 10px;
      opacity: 0.9;
      margin: 2px 5px 0 8px;
      padding-left: 0px !important;
      padding-right: 0px !important;
      &:hover {
        opacity: 1 !important;
      }
    }
  }
}

.mat-mdc-chip-list-wrapper {
  .mat-mdc-chip {
    delete-icon {
      margin: 0 !important;
      svg {
        height: auto !important;
        min-height: auto !important;
        top: 6px !important;
        cursor: pointer;
      }
    }
  }
  .mat-mdc-chip-input {
    width: 100% !important;
    caret-color: #000 !important;
  }
}
