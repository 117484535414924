@import "src/theme-styles.scss";

.app-root-page-sizing {
  min-height: calc(100vh - 70px);
  width: calc(100% - 60px);
  position: relative;
  top: 0;
  left: 60px;
  padding: 15px;
  padding-bottom: 0;
}

.page-title {
  font-size: 14px;
  font-weight: 600;
  color: $color-primary;
  margin-top: 12px;
  margin-bottom: 15px;
}
