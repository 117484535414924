.campaign-expansion {
  mat-expansion-panel {
    border: 0px solid #e2f3ff;
    background: #f8fafb;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25) !important;
    margin: 3px;
    border-radius: 4px;
    overflow: visible !important;
    .mat-expansion-panel-header {
      padding-left: 5px;
      transition: 0.3s ease;
      &.mat-expanded {
        transition: 0.3s ease;
      }
      i {
        width: 15px;
        height: 15px;
        background-image: url("../../../../assets/images/arrow-right-primary.svg");
        background-repeat: no-repeat;
        background-size: 13px;
        margin-right: 10px;
        margin-left: 5px;
        background-position: center right;
        transition: 0.1s all;
        &.down-arrow {
          transform: rotate(90deg);
        }
      }
    }
    .mat-expansion-panel-header-title {
      font-size: 14px;
      font-family: $font-family;
      font-weight: 600;
      text-transform: uppercase;
      color: $color-primary;
      span {
        color: $grid-font;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        text-transform: none;
      }
      mat-icon {
        line-height: 20px;
        width: 25px;
        height: 20px;
        font-size: 25px;
      }
    }
    .mat-expansion-panel-body {
      padding: 8px 24px 16px;
    }
    .mat-expansion-indicator {
      display: none;
    }
  }
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled="true"]):hover {
  background: transparent;
}

.mat-accordion-plato {
  .mat-accordion {
    .mat-expansion-panel {
      box-shadow: none;
      .mat-expansion-panel-header {
        background: transparent !important;
        padding: 0;
        &:hover {
          background: transparent;
        }
        &.mat-expanded {
          .mat-expansion-indicator {
            margin-top: 7px;
          }
        }
        .mat-expansion-indicator {
          margin-top: -3px;
        }
        .mat-content {
          flex: initial;
          font-family: $font-family;
          .mat-panel-title,
          .mat-expansion-panel-header-title {
            flex-grow: initial;
          }
        }
      }
      .mat-expansion-panel-content {
        font: inherit;
        .mat-expansion-panel-body {
          padding: 0;
          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
